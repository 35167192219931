<template>
  <a-modal
    title="分配网格员"
    :width="800"
    :destroyOnClose="true"
    v-model="visible"
    :confirmLoading="confirmLoading"
    @ok="saveDataApi"
    @cancel="cancelForm"
  >
    <a-form :form="form">
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="网格员"
        :required="true"
        :validateStatus="checkParams.visitorIdStatus"
        :help="checkParams.visitorIdStatusMsg"
      >
        <a-select
          style="width: 300px;"
          :value="queryParam.executorId"
          allow-clear
          show-search
          placeholder="请输入姓名/手机号关键字、ID"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="fetchingUser ? undefined : null"
          :options="searchUserList"
          @search="handleUserSearch"
          @change="handleUserChange"
          :disabled="disabled"
        >
          <template v-if="fetchingUser" #notFoundContent>
            <div style="text-align: center" v-if="isUserNull === false">
              <a-spin size="small" />
            </div>
            <div v-else>
              <div style="text-align: center">{{ notUserFoundContent }}</div>
            </div>
          </template>
        </a-select>
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="网格卡点"
        :required="true"
        :validateStatus="checkParams.fenleiStatus"
        :help="checkParams.fenleiStatusMsg"
      >
        <a-transfer
          :showSearch="true"
          :dataSource="pointList"
          :targetKeys="classifyTargetKeys"
          :render="item => item.title"
          :oneWay="true"
          @change="changeClassify"
          :listStyle="{ width: '200px', height: '350px' }"
          pagination
        >
        </a-transfer>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import {
  gridAllPoint,
  gridDealAdminCheckPoint,
  gridPointIdAdmin
} from '@/api/gridevent'
import { searchUser } from '@/api/user'
export default {
  name: 'AssignPoint',
  components: {
  },
  props: {
    statusList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      form: this.$form.createForm(this, { name: 'DealMissionFrom' }),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      loading: false,
      visible: false,
      progress: 0,
      confirmLoading: false,
      missionStatus: 0,
      checkParams: {
        visitorIdStatus: 'success',
        visitorIdStatusMsg: null,
        fenleiStatus: 'success',
        fenleiStatusMsg: null
      },
      queryParam: {
        executorId: undefined,
        userCenterId: 0,
        gridIdArr: []
      },
      pointList: [],
      classifyTargetKeys: [],
      searchUserList: [],
      searchUserTitle: '',
      timeoutUser: null,
      fetchingUser: false,
      isUserNull: false,
      notUserFoundContent: '未搜到用户',
      userData: [],
      disabled: false
    }
  },
  created () {
  },
  methods: {
    ininData (data) {
      console.log('ininData', data)
      this.checkParams.visitorIdStatus = 'success'
      this.checkParams.visitorIdStatusMsg = null
      this.checkParams.fenleiStatus = 'success'
      this.checkParams.fenleiStatusMsg = null
      this.queryParam.userCenterId = data.userCenterId
      this.queryParam.executorId = data.userId
      this.queryParam.gridIdArr = data.gridIdArr
      this.searchUserList = []
      if (this.queryParam.userCenterId > 0) {
        this.searchUserTitle = this.queryParam.userCenterId
        this.searchAllUser()
        this.disabled = true
      } else {
        this.disabled = false
      }
      this.classifyTargetKeys = []
      this.gridAllPointApi()
      if (this.queryParam.executorId !== '' && this.queryParam.executorId !== undefined) {
        this.gridPointIdAdminApi(this.queryParam.executorId, this.queryParam.userCenterId)
      }
      this.visible = true
    },
    cancelForm () {
      this.visible = false
    },
    saveDataApi () {
      const _this = this
      if (this.queryParam.executorId === '' || this.queryParam.executorId === undefined) {
        this.checkParams.visitorIdStatus = 'error'
        this.checkParams.visitorIdStatusMsg = '请选择网格员'
        return false
      } else {
        this.checkParams.visitorIdStatus = 'success'
        this.checkParams.visitorIdStatusMsg = null
      }

      if (this.classifyTargetKeys.length <= 0) {
        this.checkParams.fenleiStatus = 'error'
        this.checkParams.fenleiStatusMsg = '请选择网格卡点'
        return false
      } else {
        this.checkParams.fenleiStatus = 'success'
        this.checkParams.fenleiStatusMsg = null
      }
      this.queryParam.gridIdArr = this.classifyTargetKeys
      _this.confirmLoading = true
      gridDealAdminCheckPoint(_this.queryParam).then((res) => {
        _this.confirmLoading = false
        if (res.errorCode === 0) {
          console.log(res)
          _this.$message.success('操作成功')
          _this.cancelForm()
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        _this.confirmLoading = false
        console.log(err)
      })
    },
    gridAllPointApi () {
      const _this = this
      gridAllPoint().then((res) => {
        _this.pointList = res.result.pointList
      }).catch((err) => {
        console.log(err)
      })
    },
    gridPointIdAdminApi (userId, userCenterId) {
      const _this = this
      gridPointIdAdmin({ userCenterId: userCenterId, userId: userId }).then((res) => {
        _this.classifyTargetKeys = res.result.gridIdArr
      }).catch((err) => {
        console.log(err)
      })
    },
    handleUserSearch (e) {
      const _this = this
      _this.searchUserTitle = e.trim()
      if (_this.timeoutUser) {
        clearTimeout(_this.timeoutUser)
        _this.timeoutUser = null
      }
      _this.timeoutUser = setTimeout(function () {
        _this.searchAllUser()
      }, 500)
    },
    searchAllUser () {
      const _this = this
      if (_this.searchUserTitle === '') {
        _this.searchUserList = []
        _this.userData = []
        _this.fetchingUser = false
        _this.isUserNull = false
        return false
      }

      _this.fetchingUser = true
      _this.isMemberNull = false
      searchUser({ title: _this.searchUserTitle })
        .then((res) => {
          console.log('searchCourseApi', res)
          _this.searchUserList = res.result.selectList
          _this.userData = res.result.userData
          if (_this.searchUserList.length > 0) {
            _this.fetchingUser = false
            _this.isUserNull = false
          } else {
            _this.fetchingUser = true
            _this.isUserNull = true
          }
        })
        .catch((err) => {
          console.log(err)
          _this.fetchingUser = false
          _this.isUserNull = false
        })
    },
    handleUserChange (e) {
      console.log('handleUserChange', e)
      if (e === '' || e === undefined) {
        this.queryParam.executorId = undefined
        this.searchUserList = []
        this.checkParams.visitorIdStatus = 'error'
        this.checkParams.visitorIdStatusMsg = '请选择网格员'
      } else {
        this.queryParam.executorId = e
        this.checkParams.visitorIdStatus = 'success'
        this.checkParams.visitorIdStatusMsg = null
        this.gridPointIdAdminApi(this.queryParam.executorId, this.queryParam.userCenterId)
      }
    },
    changeClassify (targetKeys, direction, moveKeys) {
      this.classifyTargetKeys = targetKeys
      console.log('changeClassify', targetKeys)
      console.log('changeClassify', direction)
      console.log('changeClassify', moveKeys)
      if (this.classifyTargetKeys.length <= 0) {
        this.checkParams.fenleiStatus = 'error'
        this.checkParams.fenleiStatusMsg = '请选择网格卡点'
      } else {
        this.checkParams.fenleiStatus = 'success'
        this.checkParams.fenleiStatusMsg = null
      }
    }
  }
}
</script>

<style lang="less" scoped>
</style>
