import request from '@/utils/request'
const baseURL = '/baseapi'
const grideventApi = {
  GridClassifyList: '/admin/gridevent/classifyList',
  GridClassifyInfo: '/admin/gridevent/classifyInfo',
  GridSaveClassify: '/admin/gridevent/saveClassify',
  GridDelClassify: '/admin/gridevent/delClassify',
  GridPointList: '/admin/gridevent/pointList',
  GridPointInfo: '/admin/gridevent/pointInfo',
  GridSavePoint: '/admin/gridevent/savePoint',
  GridDelPoint: '/admin/gridevent/delPoint',
  GridEventList: '/admin/gridevent/eventList',
  GridEventInfo: '/admin/gridevent/eventInfo',
  GridEventTrackingList: '/admin/gridevent/eventTrackingList',
  GridDealEventStatus: '/admin/gridevent/dealEventStatus',
  GridDealAdminClassify: '/admin/gridevent/dealAdminClassify',
  GridDealAdminCheckPoint: '/admin/gridevent/dealAdminCheckPoint',
  GridSignInList: '/admin/gridevent/signInList',
  GridSignInInfo: '/admin/gridevent/signInInfo',
  GridCondition: '/admin/gridevent/condition',
  GridAllClassify: '/admin/gridevent/allClassify',
  GridAllPoint: '/admin/gridevent/allPoint',
  GridClassifyIdAdmin: '/admin/gridevent/classifyIdAdmin',
  GridPointIdAdmin: '/admin/gridevent/pointIdAdmin'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */

export function gridClassifyList (parameter) {
  return request({
    baseURL: baseURL,
    url: grideventApi.GridClassifyList,
    method: 'post',
    data: parameter
  })
}

export function gridClassifyInfo (parameter) {
  return request({
    baseURL: baseURL,
    url: grideventApi.GridClassifyInfo,
    method: 'post',
    data: parameter
  })
}

export function gridSaveClassify (parameter) {
  return request({
    baseURL: baseURL,
    url: grideventApi.GridSaveClassify,
    method: 'post',
    data: parameter
  })
}

export function gridDelClassify (parameter) {
  return request({
    baseURL: baseURL,
    url: grideventApi.GridDelClassify,
    method: 'post',
    data: parameter
  })
}

export function gridPointList (parameter) {
  return request({
    baseURL: baseURL,
    url: grideventApi.GridPointList,
    method: 'post',
    data: parameter
  })
}

export function gridPointInfo (parameter) {
  return request({
    baseURL: baseURL,
    url: grideventApi.GridPointInfo,
    method: 'post',
    data: parameter
  })
}

export function gridSavePoint (parameter) {
  return request({
    baseURL: baseURL,
    url: grideventApi.GridSavePoint,
    method: 'post',
    data: parameter
  })
}

export function gridDelPoint (parameter) {
  return request({
    baseURL: baseURL,
    url: grideventApi.GridDelPoint,
    method: 'post',
    data: parameter
  })
}

export function gridEventList (parameter) {
  return request({
    baseURL: baseURL,
    url: grideventApi.GridEventList,
    method: 'post',
    data: parameter
  })
}

export function gridEventInfo (parameter) {
  return request({
    baseURL: baseURL,
    url: grideventApi.GridEventInfo,
    method: 'post',
    data: parameter
  })
}

export function gridEventTrackingList (parameter) {
  return request({
    baseURL: baseURL,
    url: grideventApi.GridEventTrackingList,
    method: 'post',
    data: parameter
  })
}

export function gridDealEventStatus (parameter) {
  return request({
    baseURL: baseURL,
    url: grideventApi.GridDealEventStatus,
    method: 'post',
    data: parameter
  })
}

export function gridDealAdminClassify (parameter) {
  return request({
    baseURL: baseURL,
    url: grideventApi.GridDealAdminClassify,
    method: 'post',
    data: parameter
  })
}

export function gridDealAdminCheckPoint (parameter) {
  return request({
    baseURL: baseURL,
    url: grideventApi.GridDealAdminCheckPoint,
    method: 'post',
    data: parameter
  })
}

export function gridSignInList (parameter) {
  return request({
    baseURL: baseURL,
    url: grideventApi.GridSignInList,
    method: 'post',
    data: parameter
  })
}

export function gridSignInInfo (parameter) {
  return request({
    baseURL: baseURL,
    url: grideventApi.GridSignInInfo,
    method: 'post',
    data: parameter
  })
}

export function gridCondition () {
  return request({
    baseURL: baseURL,
    url: grideventApi.GridCondition,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function gridAllPoint () {
  return request({
    baseURL: baseURL,
    url: grideventApi.GridAllPoint,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function gridAllClassify () {
  return request({
    baseURL: baseURL,
    url: grideventApi.GridAllClassify,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function gridClassifyIdAdmin (parameter) {
  return request({
    baseURL: baseURL,
    url: grideventApi.GridClassifyIdAdmin,
    method: 'post',
    data: parameter
  })
}

export function gridPointIdAdmin (parameter) {
  return request({
    baseURL: baseURL,
    url: grideventApi.GridPointIdAdmin,
    method: 'post',
    data: parameter
  })
}
